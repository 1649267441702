@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.feedback-form {
  $block-name: &;

  padding-top: 20px;

  @include mq($to: md) {
    padding-top: 0;
  }

  &__body {
    display: flex;

    @include mq($to: xl) {
      display: block;
    }
  }

  &__description {
  }

  &__left {
    flex: 0 0 auto;
    width: 310px;
    margin-right: 100px;

    @include mq($to: xl) {
      width: auto;
      margin-right: 0;
      margin-bottom: 60px;
    }

    @include mq($to: md) {
      margin-bottom: 40px;
    }
  }

  &__right {
    overflow: hidden;

    // flex: 1 1 auto;
    margin-top: -3px;

    .swiper-wrapper {
      box-sizing: border-box;
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &__inner {
    padding: 70px 100px;
    border-radius: 12px;
    border-bottom: 1px dashed rgb(255 255 255 / 20%);
    background: $grad-purple-vertical-inv;

    @include mq($to: xxl) {
      padding: 50px;
    }

    @include mq($to: md) {
      padding: 20px;
    }
  }

  &__container {
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
  }

  //   #{$block-name} {
  //   }
}
